import React, { useEffect, useState } from "react";
import {
  getAllGateways,
  setSelectedGateway,
} from "../../../../redux/user/actions";
import { useDispatch, useSelector } from "react-redux";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import DepositGateway from "./depositGateway";
import { Navigate, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import DepositImg from "../../../../assets/images/icons/dc.png";
import bankImg from "../../../../assets/images/icons/bank.png";
import { getActiveBankDetails } from "../../../../redux/app/actions";
import BackIcon from "../../../../assets/images/icons/arrow-back.png";

const GatewaysList = () => {
  const dispatch = useDispatch();
  const { gatewaysList } = useSelector((state) => state.user);
  const { bankDetails } = useSelector((state) => state.app);
  const { user } = useSelector((state) => state.auth);
  const isDemoUser = user?.mstruserid === process.env.REACT_APP_DEMO_USER_ID;
  const { selectedGateway } = useSelector((state) => state.user);
  const [gateway, setGateway] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [formData, setFormData] = useState({});

  const firstElementManual = gatewaysList?.deposits
    ?.sort((a, b) => a.order - b.order)
    ?.filter((f) => f.type === "manual")?.[0];
  const firstElementAuto = gatewaysList?.deposits
    ?.sort((a, b) => a.order - b.order)
    ?.filter((f) => f.type === "auto")?.[0];
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getAllGateways());
    dispatch(getActiveBankDetails());
  }, []);
  useEffect(() => {
    if (gatewaysList) {
      setGateway(firstElementManual);
    }
  }, [gatewaysList]);
  const handleGatewayClick = (item) => {
    console.log("item", item);
    dispatch(setSelectedGateway(item));
    setFormData({});
    setGateway("");
    setIsActive(false);
    // navigate(`/deposit/${item.name}`);
  };

  const handleBankTransferClick = () => {
    // navigate(`/deposit/bank_transfer`);
    setGateway("bank_transfer");
    setIsActive(true);
  };

  return (
    <>
      {isDemoUser && <Navigate to="/" />}
      <HeaderAfterLogin />

      <main className="main mymenu-pages">
        <div className="PageHeading-container">
          <div className="PageHeading">
            <div className="back-link" onClick={() => navigate(-1)}>
              <img src={BackIcon} alt="back" />
            </div>
            <h6>Deposit</h6>
          </div>
        </div>

        <div className="inner-content">
          <div className="gatewaySection">
            <div className="gatewaypart mt-4">
              <Tabs
                defaultActiveKey="bank"
                id="uncontrolled-tab-example"
                className=""
                onSelect={(eventKey) => {
                  if (eventKey === "Deposit") {
                    setGateway(firstElementAuto);
                    dispatch(setSelectedGateway(firstElementAuto));
                  }
                  if (eventKey === "bank") {
                    setGateway(firstElementManual);
                    dispatch(setSelectedGateway(firstElementManual));
                  }
                }}
              >
                <Tab eventKey="bank" title="Instant">
                  <div className="pay-options">
                    {gatewaysList?.deposits
                      ?.sort((a, b) => a.order - b.order)
                      .filter((item) => item?.type === "manual")
                      .map((item, index) => {
                        return (
                          <div
                            className={
                              selectedGateway?.code === item?.code && !isActive
                                ? "cards1 active"
                                : "cards1 "
                            }
                            onClick={() => {
                              handleGatewayClick(item);
                            }}
                            key={index}
                          >
                            <p>{item?.name}</p>
                            <div className="card-icon">
                              <img src={item?.logo || DepositImg} />
                            </div>
                          </div>
                        );
                      })}
                    {bankDetails && (
                      <div
                        className={isActive ? "cards1 active" : "cards1 "}
                        onClick={() => {
                          handleBankTransferClick();
                        }}
                      >
                        <p>{"Bank Transfer"}</p>
                        <div className="card-icon">
                          <img src={bankImg} />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="pay-process">
                    <DepositGateway
                      formData={formData}
                      setFormData={setFormData}
                      gateway={gateway}
                    />
                  </div>
                </Tab>
                <Tab
                  eventKey="Deposit"
                  title="Crypto"
                  // onClick={() => setGateway(null)}
                >
                  <div className="pay-options">
                    {gatewaysList?.deposits
                      ?.sort((a, b) => a.order - b.order)
                      .filter((item) => item?.type === "auto")
                      .map((item, index) => {
                        return (
                          <div
                            className={
                              selectedGateway?.code === item?.code
                                ? "cards1 active"
                                : "cards1 "
                            }
                            onClick={() => {
                              handleGatewayClick(item);
                            }}
                            key={index}
                          >
                            <p>{item?.name}</p>
                            <div className="card-icon">
                              <img src={item?.logo || DepositImg} />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div className="pay-process">
                    {selectedGateway?.code && (
                      <DepositGateway
                        formData={formData}
                        setFormData={setFormData}
                        gateway={gateway}
                      />
                    )}
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </main>

      {/* <div className="d-md-none">
        <Footer />
      </div> */}
    </>
  );
};
export default GatewaysList;
