import React, { useState, useEffect } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { useNavigate, useLocation } from "react-router-dom";
import {
  mobileVerification,
  registerUser,
  sendOtpToMobile,
} from "../../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Modal } from "react-bootstrap";
import { APP_CONST } from "../../../config/const";
// import LoginLeftPart from "../../../assets/images/modal.gif";
import LoginBanner from "../../../assets/images/banner/login.jpg";
import { parsePhoneNumber } from "libphonenumber-js";
import { Toaster } from "../../../utils/toaster";
import PhoneInput from "react-phone-input-2";

const RegisterModal = ({ show, handleClose, handleModalShow }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const OTP = useSelector((state) => state?.app?.appDetails?.OTP);

  const location = useLocation();
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [mobileDetails, setMobileDetails] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [referalCode, setReferalCode] = useState(location?.state?.referalCode);
  const [agentCode, setAgentCode] = useState(location?.state?.agentCode);
  const [isReffered, setIsReffered] = useState(false);
  const [cookies, setCookie] = useCookies(["refferal"]);
  const [agentCookies, setAgentCookie] = useCookies(["agentCode"]);
  const { appDetails } = useSelector((state) => state.app);
  const [otp, setOtpValue] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");

  const [registerConfig, setRegisterConfig] = useState({
    isOtpEnabled: useSelector((state) => state?.app?.appDetails) === 0,
    mobileDetails: {},
    isSendingOtp: false,
    isVerifyingOtp: false,
    isOtpSent: false,
    isOtpVerified: false,
    showPassword: false,
    showConfirmPassword: false,
  });
  const handleRegisterConfigChange = (config) => {
    setRegisterConfig((p) => ({ ...p, ...config }));
  };
  const sendOtp = () => {
    const isOtpVerified = OTP === 0 ? false : true;
    handleRegisterConfigChange({
      isSendingOtp: true,
      isOtpVerified: isOtpVerified,
    });
    const phoneNumber = parsePhoneNumber("+" + mobileNumber);
    let payload = {};
    payload.mobile = phoneNumber.nationalNumber;
    payload.country_code = phoneNumber.countryCallingCode;

    dispatch(sendOtpToMobile(payload))
      .then((res) => {
        handleRegisterConfigChange({
          mobileDetails: payload,
          isOtpSent: true,
          isSendingOtp: false,
        });
        if (res?.data?.error === false) {
          Toaster(
            APP_CONST.TOAST_TYPE.SUCCESS,
            res?.data?.message || "OTP sent"
          );
        } else {
          Toaster(APP_CONST.TOAST_TYPE.ERROR, res?.data?.message);
        }
      })
      .catch((err) => {
        Toaster(APP_CONST.TOAST_TYPE.ERROR, err?.response?.data?.message);
        handleRegisterConfigChange({
          isSendingOtp: false,
        });
      });
  };
  const verifyOtp = () => {
    handleRegisterConfigChange({
      isVerifying: true,
    });
    dispatch(mobileVerification({ ...registerConfig.mobileDetails, otp }))
      .then((res) => {
        handleRegisterConfigChange({
          isVerifying: false,
          isOtpVerified: true,
        });
        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "OTP verified");
        handleSubmit();
      })
      .catch((err) => {
        handleRegisterConfigChange({
          isVerifying: false,
        });
        Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
      });
  };
  useEffect(() => {
    getMobileDetails();
  }, []);
  //   useEffect(() => {
  //     if (referalCode) {
  //       setCookie("code", referalCode);
  //       setReferalCode(referalCode);
  //       setIsReffered(true);
  //     } else if (cookies?.code) {
  //       setReferalCode(cookies.code);
  //       setIsReffered(true);
  //     }
  //   }, [cookies, referalCode]);

  //   useEffect(() => {
  //     if (agentCode) {
  //       setAgentCookie("agent", agentCode);
  //     } else if (agentCookies?.agent) {
  //       setAgentCode(agentCookies?.agent);
  //     }
  //   }, [agentCookies, agentCode]);
  const getMobileDetails = () => {
    const details =
      localStorage.getItem("mobile_details") &&
      JSON.parse(localStorage.getItem("mobile_details"));
    if (details) {
      setMobileDetails(details);
    }
  };
  const validateData = (data = null) => {
    const dataToValidate = data ? data : formData;
    const { name, username, password, confirmPassword, email } = dataToValidate;
    const newErrors = {};
    // if (!name || name === "") {
    //   newErrors.name = "Name cannot be empty";
    // }
    if (!username || username === "") {
      newErrors.userName = "User name cannot be empty";
    }
    if (username && (username.length < 4 || username.length > 15)) {
      newErrors.userName = "Username must be 4 to 15 characters.";
    }
    if (!/^[A-Za-z0-9 ]+$/.test(username)) {
      newErrors.userName = "Special characters are not allowed in username.";
    }
    if (appDetails?.IS_EMAIL) {
      if (!email || email === "") {
        newErrors.email = "Email cannot be empty";
      }
    }
    if (!password || password === "") {
      newErrors.password = "Password cannot be empty";
    }
    if (password && (password.length < 8 || password.length > 20)) {
      newErrors.password = "Password must be 8 to 20 characters.";
    }
    if (!confirmPassword || confirmPassword === "") {
      newErrors.confirmPassword = "Confirm password cannot be empty";
    } else if (password != confirmPassword) {
      newErrors.confirmPassword = "Password and Confirm password must be same";
    }
    setErrors(newErrors);
    return newErrors;
  };
  const handleSubmit = async () => {
    let errorsData = validateData();
    if (Object.keys(errorsData).length > 0) {
      //setErrors(errorsData);
    } else {
      let dataToSend = { ...formData, name: formData?.username };
      delete dataToSend.confirmPassword;
      if (agentCode) {
        dataToSend.agent_code = agentCode;
      }
      dispatch(
        registerUser(
          {
            ...dataToSend,
            typeId: 3,
            parentId: 81026,
            mobile: registerConfig.mobileDetails?.mobile,
            country_code: registerConfig.mobileDetails.country_code,
          },
          navigate
        )
      );
      // checkIfExistingUserDetails(formData);
    }
  };

  const handleChange = (e) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    let tempFormData = { ...formData, [fieldName]: fieldValue };
    setFormData(tempFormData);
    validateData(tempFormData);
  };
  return (
    <Modal
      show={show}
      className={"steps-canvas login-modal LoginModal LoginScroll"}
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
    >
      <Modal.Header
        closeButton
        onClick={() => handleClose(APP_CONST.AUTH_MODAL_ACTION_TYPE.MANUAL)}
        closeVariant="white"
      >
        <Modal.Title>Sign Up</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="login-section">
          {/* <div className="login-leftPart">
            <img src={LoginLeftPart} />
          </div> */}
          <div className="LoginBanner">
            <img src={LoginBanner} alt="LoginBanner" />
          </div>
          <div className="Login-rightPart">
            {/* <Modal.Title>Create an account!</Modal.Title> */}
            <div className="form-steps">
              <Form>
                {/* <Form.Group className="mb-3" controlId="formBasicName">
                  <label>Full name*</label>

                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Full name*"
                    onChange={handleChange}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.name}
                  </Form.Control.Feedback>
                </Form.Group> */}
                {(appDetails?.IS_EMAIL && (
                  <Form.Group className="" controlId="formBasicName">
                    <label>
                      Email <span>*</span>
                    </label>

                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="Email*"
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                )) ||
                  null}
                <Form.Group
                  className="position-relative"
                  controlId="formBasicName"
                >
                  <label>
                    Choose Username <span>*</span>
                  </label>
                  <Form.Control
                    type="text"
                    name="username"
                    placeholder="Choose Username*"
                    autoComplete="off"
                    onChange={handleChange}
                    isInvalid={!!errors.userName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.userName}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  className="position-relative"
                  controlId="formBasicPassword"
                >
                  <label>
                    Password <span>*</span>
                  </label>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Choose Password*"
                    autoComplete="new-password"
                    onChange={handleChange}
                    isInvalid={!!errors.password}
                  />
                  <span
                    className="icon"
                    onClick={() => setShowPassword((p) => !p)}
                  >
                    {showPassword ? <IoMdEyeOff /> : <IoMdEye />}
                  </span>
                  <Form.Control.Feedback type="invalid">
                    {errors?.password}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="position-relative"
                  controlId="formBasicPassword"
                  style={{ marginBottom: "0 !important" }}
                >
                  <label>
                    Confirm Password <span>*</span>
                  </label>
                  <Form.Control
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    placeholder="Confirm Password*"
                    autoComplete="new-password"
                    onChange={handleChange}
                    isInvalid={!!errors.confirmPassword}
                  />
                  <span
                    className="icon"
                    onClick={() => setShowConfirmPassword((p) => !p)}
                  >
                    {showConfirmPassword ? <IoMdEyeOff /> : <IoMdEye />}
                  </span>
                  <Form.Control.Feedback type="invalid">
                    {errors?.confirmPassword}
                  </Form.Control.Feedback>
                </Form.Group>
                {
                  <Form.Group
                    className="position-relative"
                    controlId="formBasicPassword"
                  >
                    <label>Please enter agent code</label>
                    <Form.Control
                      type={"text"}
                      name="agent_code"
                      placeholder="Please enter agent code"
                      value={agentCode}
                      onChange={(e) => setAgentCode(e.target.value)}
                    />
                  </Form.Group>
                }
                <div className="get-otp">
                  <Form.Group
                    className="position-relative"
                    controlId="formBasicPassword"
                  >
                    <label>Phone Number</label>
                    <PhoneInput
                      country={"in"}
                      countryCodeEditable={false}
                      inputProps={{
                        name: "phone",
                        required: true,
                      }}
                      inputStyle={{ width: "100%" }}
                      onChange={(phone) => {
                        setMobileNumber(phone);
                      }}
                    />
                  </Form.Group>

                  {/* <Button
                    variant="primary"
                    onClick={sendOtp}
                    disabled={registerConfig.isSendingOtp}
                  >
                    {registerConfig.isSendingOtp
                      ? "Loading.."
                      : registerConfig.isOtpSent === true
                      ? "Resend OTP"
                      : "Get OTP"}
                  </Button> */}
                  {/* <p className="otpnote">
                    You can request a new otp after every 05 minutes
                  </p> */}
                </div>
                {OTP === 0 && registerConfig.isOtpSent === true ? (
                  <div className="verify-otp get-otp" style={{marginTop:"18px"}}>
                    <label>Enter OTP</label>
                    <Form.Group
                      className="mb-4 position-relative"
                      controlId="formBasicPassword"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Enter OTP*"
                        onChange={(e) => setOtpValue(e.target.value)}
                      />
                    </Form.Group>

                    {/* <Button
                      variant="primary"
                      onClick={verifyOtp}
                      disabled={
                        registerConfig.isVerifyingOtp ||
                        !registerConfig.isOtpSent ||
                        !otp
                      }
                    >
                      {!registerConfig.isOtpVerified
                        ? !registerConfig.isVerifyingOtp
                          ? "VERIFY OTP"
                          : "SUBMITTING"
                        : "VERIFIED"}
                    </Button> */}
                  </div>
                ) : null}

                {/* <Button
                  variant="primary"
                  className="SubmitBtn"
                  type="submit"
                  onClick={handleSubmit}
                  // disabled={!registerConfig.isOtpVerified}
                >
                  SUBMIT
                </Button> */}
                {registerConfig.isOtpSent === false && (
                  <Button
                    variant="primary"
                    onClick={sendOtp}
                    disabled={registerConfig.isSendingOtp}
                  >
                    {registerConfig.isSendingOtp
                      ? "Loading.."
                      : registerConfig.isOtpSent === true
                      ? "Submit / Resend OTP"
                      : "Submit"}
                  </Button>
                )}

                {registerConfig.isOtpSent === true && (
                  <Button
                    variant="primary"
                    onClick={verifyOtp}
                    disabled={
                      registerConfig.isVerifyingOtp ||
                      !registerConfig.isOtpSent ||
                      !otp
                    }
                  >
                    {!registerConfig.isOtpVerified
                      ? !registerConfig.isVerifyingOtp
                        ? "VERIFY OTP"
                        : "SUBMITTING"
                      : "VERIFIED"}
                  </Button>
                )}
              </Form>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default RegisterModal;
