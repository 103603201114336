export const gatewayFormValidator = (
  fields = [],
  dataToValidate = {},
  GatewayType
) => {
  let errorObject = {};

  fields.forEach((f) => {
    if (f.required && f.display) {
      if (dataToValidate?.[f?.accessor]) {
        let value = dataToValidate?.[f?.accessor];
        if (!Boolean(value)) {
          errorObject[f?.accessor] = `${f?.accessor} is required`;
        } else {
          let minAmount = GatewayType ? 300 : 1000;
          if (f?.accessor === "amount") {
            if (value > 100000) {
              errorObject[f?.accessor] = "Maximum deposit amount is 1,00,000";
            }
          }
        }
      } else {
        errorObject[f?.accessor] = `${f?.accessor} is required`;
      }
    }
  });
  return errorObject;
};
