import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { gatewayFormValidator } from "../../../../utils/gatewayFormValidator";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
// import QRCodeCard from "../../../../utils/QrCodeCard";
import QRCodeCard from "../../../../utils/QrCodeCard";
import {
  updateDepositAmount,
  createDeposit
} from "../../../../redux/user/actions";
import { APP_CONST } from "../../../../config/const";
import { Toaster } from "../../../../utils/toaster";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import { Container, Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { getActiveBankDetails } from "../../../../redux/app/actions";
// import CopyToClipboard from "../../../components/copyToClipBoard";
import CopyToClipboard from "react-copy-to-clipboard";
import BackIcon from "../../../../assets/images/icons/arrow-back.png";
import ArrowNext from "../../../../assets/images/arrow-next.png";

import { AiFillCopy } from "react-icons/ai";
import LeftBarSports from "../../../components/LeftBarSports";
import numberWithCommas from "../../../../utils/numbersWithComma";

import CrossBtn from "../../../../assets/images/icons/close_btn-icon.png";

import Paytm from "../../../../assets/images/icons/paytm-pay.png";
import GooglePay from "../../../../assets/images/icons/g-pay.png";
import PhonePe from "../../../../assets/images/icons/phonepe-pay.png";
import UPI from "../../../../assets/images/icons/upi-pay.png";

const CUSTOM_DEPOSIT_RESPONSE_TYPE = {
  QRCODE: "QRCODE",
  IMAGE: "IMAGE"
};

const DepositGateway = ({ gateway, formData, setFormData }) => {
  const { selectedGateway, gatewaysList } = useSelector((state) => state.user);
  const { bankDetails } = useSelector((state) => state.app);
  const [showAllFields, setShowAllFields] = useState(false);

  const dispatch = useDispatch();
  console.log(showAllFields);
  const [isLoading, setIsLoading] = useState(false);
  // const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  // const { gateway } = useParams();
  const isBank = gateway === "bank_transfer";
  const showQrImg = selectedGateway?.qrCode !== null;
  const [dAmount, setDAmount] = useState(null);
  const [show, setShow] = useState(false);
  const paymentOption = [
    {
      id: 1,
      name: "Paytm",
      img: Paytm
    },
    {
      id: 2,
      name: "Google Pay",
      img: GooglePay
    },
    {
      id: 3,
      name: "Phone Pay",
      img: PhonePe
    },
    {
      id: 4,
      name: "Upi",
      img: UPI
    }
  ];
  const [customdepositResponseType, setCustomDepositResponseType] =
    useState(null);
  const [customActionDepositResponse, setCustomActionDepositResponse] =
    useState(null);

  useEffect(() => {
    dispatch(getActiveBankDetails());
  }, []);

  const validateData = (dataToValidate) => {
    let GatewayType = isBank;
    let fields = selectedGateway.fields;
    let validations = gatewayFormValidator(fields, dataToValidate,GatewayType);
    setErrors(validations);
    return validations;
  };

  const validateBankFormData = (data) => {
    const { amount, utrNo, txnImage } = data;
    let errorVar = {};
    let minAmount = selectedGateway?.type === "manual" ? 300 : 1000;
    if (!amount) {
      errorVar.amount = "Amount is required";
    }
    if (amount < minAmount || amount > 100000) {
      errorVar.amount =
        amount < minAmount
          ? `Minimum deposit amount is ${minAmount}`
          : "Maximum deposit amount is 1,00,000";
    }
    if (!utrNo) {
      errorVar.utrNo = "UTR is required";
    }
    if (!txnImage) {
      errorVar.txnImage = "Txn image is required";
    } else if (txnImage && txnImage?.size > 2000000) {
      errorVar.txnImage = "Image size must be less than 2MB";
    }
    setErrors(errorVar);
    return errorVar;
  };

  const handleChange = (e, type = "non-file") => {
    let name = e.target.name;
    let value = e.target.value;
    if (type === "file") {
      value = e.target.files?.[0];
    }
    let data = { ...formData, [name]: value };
    if (name === "amount") {
      setDAmount(value);
    }
    if (isBank) {
      validateBankFormData(data);
    } else {
      validateData(data);
    }

    setFormData(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isBank) {
      if (Object.values(validateData({ ...formData })).length == 0) {
        // console.log("formData", formData);
        if (
          selectedGateway.fields.some(
            (f) => f?.accessor === "gateway" && f?.required === true
          )
        ) {
          formData["gateway"] = selectedGateway?.code;
        }
        if (selectedGateway?.slug && selectedGateway?.slug !== null) {
          formData.slug = selectedGateway?.slug;
        }
        setIsLoading(true);
        const response = await updateDepositAmount({
          data: formData,
          url: selectedGateway?.url,
          isMultiPart: selectedGateway?.fields.some((f) => f?.type === "file")
        });
        setIsLoading(false);
        handleSubmitResponse(response);
        // console.log("response", response);
      }
    } else {
      if (Object.values(validateBankFormData(formData)).length === 0) {
        setIsLoading(true);
        const res = await createDeposit({ ...formData, utrNo: null });
        setIsLoading(false);
        if (res && res?.data) {
          Toaster(APP_CONST.TOAST_TYPE.SUCCESS, res?.data?.data?.message);
          navigate("/home");
        }
      }
    }
  };

  //TODO  Add it for url
  const handleSubmitResponse = (value) => {
    if (value) {
      if (selectedGateway?.type === "manual") {
        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, value.data.data.message);
        navigate("/home");
      } else if (value?.data?.data?.data && "payUrl" in value?.data.data.data) {
        // Redirect url
        window.location.href = value?.data?.data?.data?.payUrl;
      } else if (
        value?.data?.data?.data &&
        "payImageUrl" in value?.data.data.data
      ) {
        // Show image
        setCustomActionDepositResponse(value?.data?.data?.data?.payImageUrl);
        setCustomDepositResponseType(CUSTOM_DEPOSIT_RESPONSE_TYPE.IMAGE);
      } else if (
        value?.data?.data?.data &&
        "qrContent" in value?.data.data.data
      ) {
        // Show qr code
        setCustomActionDepositResponse(value?.data?.data?.data?.qrContent);
        setCustomDepositResponseType(CUSTOM_DEPOSIT_RESPONSE_TYPE.QRCODE);
      } else if (value?.data?.data?.data && "url" in value?.data.data.data) {
        // Redirect url
        window.location.href = value?.data?.data?.data?.url;
      } else if (selectedGateway.code === "alphapo") {
        window.location.href = value?.data?.data?.data?.url;
      } else if (selectedGateway.code === "coinbase") {
        window.location.href = value?.data?.data?.data?.url;
      }
    }
  };

  const renderFields = (field) => {
    if (!showAllFields && field.accessor !== "amount") {
      return null;
    }
    switch (field.type) {
      case "file":
        return (
          <>
            <Form.Group
              className="mb-3 position-relative"
              controlId="formBasicUserName"
            >
              <Form.Control
                type={field.type}
                placeholder={field.label}
                name={field.accessor}
                onChange={(e) => handleChange(e, "file")}
                style={{ display: !field.display ? "none" : "" }}
                isInvalid={!!errors[field.accessor]}
              />
              <span class="focus-border">
                <i></i>
              </span>
              <Form.Control.Feedback type="invalid">
                {errors[field.accessor]}
              </Form.Control.Feedback>
            </Form.Group>
          </>
        );
      default:
        return (
          <>
            <Form.Group
              className="mb-3 position-relative"
              controlId="formBasicUserName "
            >
              <Form.Control
                type={field.type}
                // value={dAmount || ""}
                placeholder={field.label}
                name={field.accessor}
                onChange={handleChange}
                style={{ display: !field.display ? "none" : "" }}
                isInvalid={!!errors[field.accessor]}
              />
              <span class="focus-border">
                <i></i>
              </span>
              <Form.Control.Feedback type="invalid">
                {errors[field.accessor]}
              </Form.Control.Feedback>
            </Form.Group>
          </>
        );
    }
  };

  const renderBankTransferForm = () => {
    return (
      <Form className="gatewayForm">
        <Form.Group
          className="mb-3 position-relative"
          controlId="formBasicUserName"
        >
          <Form.Control
            type={"number"}
            placeholder={"Enter amount"}
            name={"amount"}
            onChange={(e) => handleChange(e)}
            // style={{ display: !field.display ? "none" : "" }}
            isInvalid={!!errors["amount"]}
          />
          <span class="focus-border">
            <i></i>
          </span>
          <Form.Control.Feedback type="invalid">
            {errors["amount"]}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group
          className="mb-3 position-relative"
          controlId="formBasicUserName"
        >
          <Form.Control
            type={"text"}
            placeholder={"Enter UTR no"}
            name={"utrNo"}
            onChange={(e) => handleChange(e)}
            // style={{ display: !field.display ? "none" : "" }}
            isInvalid={!!errors["utrNo"]}
          />
          <span class="focus-border">
            <i></i>
          </span>
          <Form.Control.Feedback type="invalid">
            {errors["utrNo"]}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group
          className="mb-3 position-relative"
          controlId="formBasicUserName"
        >
          <Form.Control
            type={"file"}
            placeholder={"Select file"}
            name={"txnImage"}
            onChange={(e) => handleChange(e, "file")}
            // style={{ display: !field.display ? "none" : "" }}
            isInvalid={!!errors["txnImage"]}
          />
          <span class="focus-border">
            <i></i>
          </span>
          <Form.Control.Feedback type="invalid">
            {errors["txnImage"]}
          </Form.Control.Feedback>
        </Form.Group>
        <Button
          className="px-5 mb-4"
          variant="primary"
          onClick={handleSubmit}
          style={{ fontSize: "15px", borderRadius: "3px" }}
          disabled={isLoading}
        >
          {isLoading ? "Loading.." : "Submit"}
        </Button>
      </Form>
    );
  };

  const renderCustomActionResponse = () => {
    if (customdepositResponseType === CUSTOM_DEPOSIT_RESPONSE_TYPE.IMAGE) {
      return (
        <div>
          <div className="ml-4 ">
            <div className="bg-zinc-50 rounded-lg p-3 qrSize">
              <img src={customActionDepositResponse} />
            </div>
          </div>
        </div>
      );
    } else if (
      customdepositResponseType === CUSTOM_DEPOSIT_RESPONSE_TYPE.QRCODE
    ) {
      return (
        <div>
          <QRCodeCard text={customActionDepositResponse} />
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {/* <HeaderAfterLogin /> */}

      {customActionDepositResponse && customdepositResponseType ? (
        <>
          <div className="HpaySection">
            <div
              className="HayQR"
              onClick={() => {
                setCustomActionDepositResponse(null);
                setCustomDepositResponseType(null);
              }}
            >
              <img src={CrossBtn} alt="CrossBtn" />
            </div>
            {renderCustomActionResponse()}
          </div>
        </>
      ) : (
        <main className="main mymenu-pages">
          {!isBank ? (
            <>
              <div className="PageHeading-container">
                {/* <div className="PageHeading">
                  <div className="back-link" onClick={() => navigate(-1)}>
                    <img src={BackIcon} alt="back" />
                  </div>
                  <h6>{selectedGateway?.name}</h6>
                </div> */}
                <h6>{selectedGateway?.name}</h6>
              </div>

              <Form className="gatewayForm">
                {selectedGateway?.type === "manual" && (
                  <div
                    onClick={() => setShowAllFields(false)}
                    className="reset"
                  >
                    Reset
                  </div>
                )}
                {selectedGateway?.fields?.map((f, i) => {
                  return renderFields(f);
                })}
                {showAllFields && (
                  <>
                    <div className="PaymentOptions">
                      {paymentOption.map((item) => {
                        return (
                          <div variant="primary" onClick={() => setShow(true)}>
                            <img src={item?.img} />
                          </div>
                        );
                      })}
                    </div>
                    <Modal
                      show={show}
                      onHide={() => setShow(false)}
                      className="DepositQR"
                    >
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body>
                        {(selectedGateway?.showQr === true && (
                          <div className="DepositQR mt-2">
                            {showQrImg ? (
                              <div className="mx-auto w-full max-w-[16rem] relative">
                                <h2>Scan QR code to pay</h2>
                                <div className="ml-4">
                                  <div className="bg-zinc-50 d-flex items-center justify-content-center rounded-lg  qrSize">
                                    <img
                                      src={selectedGateway?.qrCode}
                                      style={{ width: "280px" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <QRCodeCard text={selectedGateway?.value} />
                            )}
                          </div>
                        )) ||
                          null}
                      </Modal.Body>
                    </Modal>
                    {selectedGateway?.type === "manual" && (
                      <>
                        <h6 className="mt-3">
                          <strong>Method 2</strong>(Copy payment details)
                        </h6>
                        <div className="valuesDeposit mt-2">
                          {/* <h3>Wallet : {selectedGateway?.value}</h3> */}
                          <h5>UPI : {selectedGateway?.displayValue}</h5>

                          <div className="copyValue d-flex">
                            <CopyToClipboard
                              text={selectedGateway?.displayValue}
                              onCopy={() => {
                                Toaster(
                                  APP_CONST.TOAST_TYPE.SUCCESS,
                                  "Text Copied"
                                );
                              }}
                            >
                              <AiFillCopy
                                style={{ width: "16px", height: "16px" }}
                              />
                            </CopyToClipboard>
                            {/* <CopyToClipboard
                      text={selectedGateway?.value?.slice(index1, index2)}
                      onCopy={() => {
                        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                      }}
                    /> */}
                            <div>Click to copy code</div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
                <span class="focus-border">
                  <i></i>
                </span>

                {selectedGateway?.type === "auto" ? (
                  <Button
                    className={
                      selectedGateway?.type === "auto"
                        ? "next-btn"
                        : "px-4 mb-4"
                    }
                    onClick={handleSubmit}
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading.." : "Submit"}
                  </Button>
                ) : showAllFields ? (
                  <Button
                    className={
                      selectedGateway?.type === "auto"
                        ? "next-btn"
                        : "px-4 mb-4"
                    }
                    onClick={handleSubmit}
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading.." : "Submit"}
                  </Button>
                ) : (
                  <Button
                    className={
                      selectedGateway?.type === "auto"
                        ? "next-btn"
                        : "px-4 mb-4"
                    }
                    onClick={() => setShowAllFields(true)}
                    // disabled={isLoading}
                  >
                    {isLoading ? "Loading.." : "Submit"}
                  </Button>
                )}
              </Form>
            </>
          ) : (
            <>
              <div className="PageHeading-container">
                {/* <div className="PageHeading">
                  <div className="back-link" onClick={() => navigate(-1)}>
                    <img src={BackIcon} alt="back" />
                  </div>
                  <h6>{"Bank Transfer"}</h6>
                </div> */}
              </div>
              {bankDetails && (
                <div
                  className="mb-4 bankdetails"
                  style={{
                    background: "#111",
                    padding: "15px",
                    borderRadius: "5px"
                  }}
                >
                  <p>
                    Account name : {bankDetails?.acc_name}{" "}
                    <CopyToClipboard
                      text={bankDetails?.acc_name}
                      onCopy={() => {
                        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                      }}
                    >
                      <AiFillCopy />
                    </CopyToClipboard>
                    {/* <CopyToClipboard
                    text={bankDetails?.acc_name}
                    onCopy={() => {
                      Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                      console.log("Abc");
                    }}
                  /> */}
                  </p>
                  <p>
                    Account number : {bankDetails?.acc_no}{" "}
                    <CopyToClipboard
                      text={bankDetails?.acc_no}
                      onCopy={() => {
                        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                      }}
                    >
                      <AiFillCopy />
                    </CopyToClipboard>
                    {/* <CopyToClipboard
                    text={bankDetails?.acc_no}
                    onCopy={() => {
                      Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                    }}
                  /> */}
                  </p>
                  <p>
                    IFSC code : {bankDetails?.ifsc_code}{" "}
                    <CopyToClipboard
                      text={bankDetails?.ifsc_code}
                      onCopy={() => {
                        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                      }}
                    >
                      <AiFillCopy />
                    </CopyToClipboard>
                    {/* <CopyToClipboard
                    text={bankDetails?.ifsc_code}
                    onCopy={() => {
                      Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                    }}
                  /> */}
                  </p>
                  <p className="mb-0">
                    Bank name : {bankDetails?.bank_name}{" "}
                    <CopyToClipboard
                      text={bankDetails?.bank_name}
                      onCopy={() => {
                        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                      }}
                    >
                      <AiFillCopy />
                    </CopyToClipboard>
                    {/* <CopyToClipboard
                    text={bankDetails?.bank_name}
                    onCopy={() => {
                      Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                    }}
                  /> */}
                  </p>
                </div>
              )}
              {bankDetails && renderBankTransferForm()}
            </>
          )}
          {selectedGateway?.type === "manual" && (
            <ul className="DpositContent">
              <li>
                1. After Deposit, Upload Slip and Enter your deposit amount.
              </li>
              <li>2. Copy and Enter 12 Digit UTR number.</li>
              <li>
                3. Click on Submit button and receive Points and Bonus
                instantly.
              </li>
              <li>
                4. Winz11 is not responsible for money deposited to old inactive
                or closed accounts.
              </li>
              <li>5. NEFT receiving times vary from 40 mins to 2 hours.</li>
              <li>6. Only UTR and RRN Number will be accepted.</li>
              <li>
                7. Prior to initiating the deposit, kindly check the active
                payment details as the deposit details will be changed from time
                to time.
              </li>
              <li>
                8. Saving the bank details and transferring funds to any old or
                inactive account results in not being credited to your gaming
                account or refunded to you.
              </li>
              <li>
                9. Any deposit less than 500 will not be refunded and the
                deposited amount will not be processed.
              </li>
            </ul>
          )}
        </main>
      )}

      {/* <div className="d-md-none">
        <Footer />
      </div> */}
    </>
  );
};
export default DepositGateway;
